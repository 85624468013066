.organization-tree { margin: 1em; }

.organization-tree input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  }

.organization-tree input ~ ul { display: none; }

.organization-tree input:checked ~ ul { display: block; }

/* ————————————————————–
  organization-tree rows
*/
.organization-tree li {
  line-height: 1.2;
  position: relative;
  padding: 0 0 1em 1em;
  }

.organization-tree ul li { padding: 1em 0 0 1em; }

.organization-tree > li:last-child { padding-bottom: 0; }

/* ————————————————————–
  organization-tree labels
*/
.organization-tree_label {
  position: relative;
  display: inline-block;
  }

label.organization-tree_label { cursor: pointer; }

label.organization-tree_label:hover { color: #666; }

/* ————————————————————–
  organization-tree expanded icon
*/
label.organization-tree_label:before {
  background: #000;
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 1em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: '+';
  text-align: center;
  line-height: .9em;
  }

:checked ~ label.organization-tree_label:before { content: '–'; }

/* ————————————————————–
  organization-tree branches
*/
.organization-tree li:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -.5em;
  display: block;
  width: 0;
  border-left: 1px solid #777;
  content: "";
  }

.organization-tree_label:after {
  position: absolute;
  top: 0;
  left: -1.5em;
  display: block;
  height: 0.5em;
  width: 1em;
  border-bottom: 1px solid #777;
  border-left: 1px solid #777;
  border-radius: 0 0 0 .3em;
  content: '';
  }

label.organization-tree_label:after { border-bottom: 0; }

:checked ~ label.organization-tree_label:after {
  border-radius: 0 .3em 0 0;
  border-top: 1px solid #777;
  border-right: 1px solid #777;
  border-bottom: 0;
  border-left: 0;
  bottom: 0;
  top: 0.5em;
  height: auto;
  }

.organization-tree li:last-child:before {
  height: 1em;
  bottom: auto;
  }

.organization-tree > li:last-child:before { display: none; }

.organization-tree_custom {
  display: block;
  padding: 1em;
  border-radius: 0.3em;
}