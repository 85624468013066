@import "./capabilityReportTimeSlider";
@import "./capabilityReport";
@import "./goalsReport";
@import "./activityReport";

.modalbg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  animation: fade-in 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .dialog {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    animation: move-in 0.5s;
  }
}

.close {
  background: #606061;
  color: #ffffff;
  width: 24px;
  border-radius: 12px;
  transition: all 0.1s ease-out;

  &:hover {
    background: #ff5252;
    transition: all 0.1s ease-out;
  }
}

.start-node {
  transform: translate(10px, -80px);
}

.reports {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  margin-top: 30px;
  border-radius: 12px;
  margin-bottom: 200px;
  height: 90%;
}

.top-report {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
  padding: 20px;
  height: 3rem;
}

.rotated-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  background-color: #ffdd95;
}

.rotated-element {
  padding: 10px;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"],
  input[type="radio"] {
    --active: #ffaf00;
    --active-inner: #fff;
    --focus: 2px rgba(222, 152, 0, 0.3);
    --border: #bbc1e1;
    --border-hover: #ffaf00;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 4px;
    }
  }
  input[type="checkbox"] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
  input[type="checkbox"] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

.backlog {
  display: flex;
  height: 100%;
  margin-right: 35px;

  .first-level-capability {
    margin-left: 0;
    width: 80%;
  }
}

.backlog-button {
  background-repeat: no-repeat;
  background-position: center;
  width: 27px;
  height: 27px;
  padding: 6px;
  background-color: #eee;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s;
}
