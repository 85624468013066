.error-notice {
	position: absolute;
	top: 10%;
	right: 7%;
	z-index: 999;
}

.success-notice {
	position: absolute;
	top: 20%;
	right: 7%;
	z-index: 990;
}