.tree-wrapper {
    display: flex;
    width: 100vw;
    height: 100%;
}

.hint {
	position: absolute;
	padding: 8px;
	background: rgba(0, 0, 0, 0.8);
	color: #fff;
	border-radius: 4px;
	pointer-events: none;
	font-size: 12px;
}

.hint:before, .hint:after {
    position: absolute;
    opacity: 0;
    z-index: 1000000;
    -webkit-transition: 0.3s ease;
    -moz-transition: 0.3s ease;
  pointer-events: none;
}

.hint:hover:before, .hint:hover:after {
	opacity: 1;
}

.hint:before {
	content: '';
	position: absolute;
	background: transparent;
	border: 6px solid transparent;
	position: absolute;
}
		
.hint:after {
	content: attr(data-hint);
	background: rgba(0, 0, 0, 0.8);
	color: white;
	padding: 8px 10px;
	font-size: 12px;
	white-space: nowrap;
	box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}

.hint--bottom:before {
	top: 100%;
	left: 50%;
	margin: -14px 0 0 0;
	border-bottom-color: rgba(0, 0, 0, 0.8);
}
		
.hint--bottom:after {
	top: 100%;
	left: 50%;
	margin: -2px 0 0 -10px;
}

.hint--bottom:hover:before {
	margin-top: -6px;
}

.hint--bottom:hover:after {
	margin-top: 6px;
}

.node__branch > circle {
	stroke-width: 50px !important;
	fill: blue;
}

#tree {
	display: inline-block;
	padding: 10px;

	* {
		box-sizing: border-box;
	}

	.branch {
		padding: 5px 0 5px 20px;

		&:not(:first-child) {
			margin-left: 170px;

			&:after {
				content: "";
				width: 20px;
				border-top: 1px solid #ccc;
				position: absolute;
				left: 150px;
				top: 50%;
				margin-top: 1px;
			}
		}
	}
}