@import "./vars";
@import "./reports/reports";
@import "./fonts/fonts";
@import "./ui/ui";
@import "./features/features";
@import "./pages/pages";
@import "./sign-up/sign-up";
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scrollbar-gutter: stable;

  *::-webkit-scrollbar,
  *::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    color: $main-color;
  }

  *::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
  }
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #eeeef2;
  scrollbar-gutter: stable;
  overflow-y: auto;
  scroll-margin: 20px;
}

header {
  height: 2%;
}

html,
body,
#root,
.wrapper {
  height: 100%;
}

main {
  height: 98%;
}

.nav_min li {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  &:first-child {
    margin-top: 34px;
  }
}

.nav_max li {
  display: flex;
  flex-direction: column;
  margin: 10px;
  font-size: 12px;
}

.nav-transition-enter {
  opacity: 0;
}

.nav-transition-enter-active {
  opacity: 1;
  transition: opacity 150ms;
}

.nav-transition-exit {
  opacity: 1;
}

.nav-transition-exit-active {
  opacity: 0;
  transition: opacity 150ms;
}

.active {
  background-color: $second-color;
  border-radius: 8px;
  transition: all 0.2s;
  color: #fff !important;
}

.accent-color {
  color: $accent-color;
}

.second-color {
  color: $second-color;
}

.second-color-hover {
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $second-color-hover;
    opacity: 100%;
  }
}

.bg-second-color {
  background-color: $second-color;

  &:hover {
    background-color: $second-color-hover;
  }
}

.content {
  scrollbar-gutter: stable;
  margin: 1.5rem;
  overflow-y: auto;
}

.content-mini {
  grid-column: span 11 / span 11;
}

.content-max {
  grid-column: span 10 / span 10;
}

.icon-img {
  background-color: #eee;
  color: rgb(79, 79, 79);
  font-size: 1.5rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.icon-img-active {
  background-color: $second-color;
  color: #fff;
  font-size: 1.5rem;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.hover-bg {
  background: $second-color;
  border-radius: 8px;
  width: 260px;
  height: 43px;
}

.bghover-default {
  background: $second-color;
  border-radius: 8px;
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-bg {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 5px;
}

.dict-list {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 5px;
}

.settings {
  display: flex;
  background-color: #ffffff;
  width: 100%;
  padding: 40px 80px;
  border-radius: 12px;
  min-height: 500px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.settings_dictionaries {
  display: flex;
  background-color: #ffffff;
  width: 100%;
  padding: 40px 80px;
  border-radius: 12px;
  min-height: 500px;
  flex-wrap: wrap;
  place-content: space-between;
  gap: 1;
}

.tables {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 100%;
  margin-top: 30px;
  scroll-margin-bottom: 20px;
  padding: 20px 20px 20px 20px;
  border-radius: 12px;
  overflow-x: scroll;
  // scrollbar-gutter: stable;
  min-height: 500px;
}

.top-table {
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
  height: 3rem;
}

.table-header {
  width: 100%;
  gap: 10px;
  border-spacing: 5px;
  height: 50px;
  background-color: $main-color;
}

.table-header-item {
  position: relative;
  color: #fff;
  padding: 13px;
  margin: 5px;
  font-size: 12px;
  height: 10px;
  z-index: auto;
}

.table-header-item__container {
  display: flex;
  align-items: center;
}

.table-header-item {
  &:first-child {
    border-top-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    margin-right: 150px;
    width: 5%;
  }
}

.table-body tr {
  font-size: 12px;
  height: 45px;
}

.header-item_link {
  min-width: 150px;
}

.header-item_name {
  width: 30%;
}

.header-item_id {
  width: 5%;
}

.logo-size {
  display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;

  svg {
    // background-color: #fff;
    // width: 60%;
    height: 100%;
  }
}

.filter-active {
  color: $second-color;
}

.table-body tr {
  &:nth-of-type(odd) {
    background-color: #f5f5f5;
  }

  &:hover {
    background-color: $second-color;
    color: #fff;

    .link a,
    .capability-controls__button,
    .capability-controls button {
      color: #fff;
    }
  }
}

.max-navigation {
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.mini-navigation {
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
}

.capability-controls {
  display: flex;
  margin-left: 50px;

  &__button {
    color: #c1c1c1;
    font-size: 14px;
    max-width: none;
    cursor: pointer;
  }

  button {
    color: #c1c1c1;
    font-size: 14px;
    max-width: none;
    cursor: pointer;
  }
}

.search-bar_capabilities {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  height: 30px;
  background-color: #f3f4f6;
  border-radius: 10px;
  padding: 0 10px;

  input {
    background-color: #f3f4f6;
    border-radius: 10px;
    font-size: 12px;
    height: 30px;
    outline: none;
    width: 200px;
  }
}

.capability-filter {
  font-size: 18px;
}

.filter-select {
  position: absolute;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
  padding-right: 10px;
  z-index: 999;
  top: 50px;
  left: 35px;
  background-color: $main-color;
  width: 220px;
  // height: 180px;
  border-radius: 5px;
  padding: 5px;
}

.filter-select__form {
  margin-left: 10px;

  label {
    display: flex;
  }

  input {
    margin-right: 5px;
  }
}

.filter-input {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  z-index: 999;
  top: 50px;
  left: 35px;
  background-color: $main-color;
  width: 200px;
  height: 40px;

  input {
    outline: none;
    background-color: $main-color;
    width: 150px;
    height: 40px;
  }
}

.filter-input-capability-parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 999;
  top: 50px;
  left: 35px;
  background-color: $main-color;
  width: 200px;
  height: 80px;

  .parent-num {
    display: flex;
    margin-left: 15px;

    input {
      outline: none;
      background-color: $main-color;
      width: 140px;
      height: 40px;
    }
  }
}

.clear-field {
  font-size: 22px;
  margin: 0 5px 0 10px;
  background-color: $main-color;
}

.address-sort {
  display: flex;
  position: absolute;
  z-index: 999;
  top: 50px;
  left: 35px;
  background-color: $main-color;
  width: 280px;
  height: 40px;
  border-radius: 5px;

  input {
    width: 80px;
    background-color: $main-color;
    box-shadow: none;
    outline: none;
    height: 40px;
  }
}

.period-symbol {
  font-weight: bold;
  font-size: 24px;
  padding-top: 36px;
}

.level-inputs {
  display: flex;
  gap: 0px;
}

.level-input {
  width: 5.5rem;
  margin: 0;
}

.plus-minus,
.pagination {
  display: flex;
  padding: 20px;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  transition: all 0.2s;

  img {
    min-width: 27px;
  }

  button {
    min-width: 27px;
  }
}

.general {
  width: 25px;
  height: 25px;
  background: #eeeff2;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chosenDelete {
  width: 25px;
  height: 25px;
  background: $second-color;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs-container {
  width: 100%;
}

.section-tab {
  background-color: white;
  width: 25%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 7px 50px;
  border-radius: 10px;
  margin-right: 50px;
  cursor: pointer;
  min-height: 38px;

  &:last-child {
    margin-right: 0;
  }
}

.rotate-image {
  transform: rotate(180deg);
}

.capabilities {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  gap: 10px;
  // transition: all 1s ease-in-out;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.top-inputs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
  width: 100%;

  &__container {
    width: 20%;
  }

  &__field {
    width: 100%;
  }
}

.modal-save {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #eeeef2;
  border-radius: 16px;
  cursor: move;
}

.save-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  position: absolute;
  background-color: #fff;
  top: -350px;
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
  width: 300px;
  box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.5);
  cursor: move;

  button {
    margin: 0 10px;
  }

  p {
    margin-top: 25px;
    padding: 0;
  }

  .close {
    margin: 0;
  }

  input {
    width: 190px;
    margin-bottom: 20px;

    &:focus {
      outline: none;
    }
  }
}

.save-controls-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  position: absolute;
  background-color: #fff;
  top: -350px;
  padding: 20px;
  border-radius: 10px;
  z-index: 999;
  width: 550px;
  box-shadow: 0px 10px 21px 0px rgba(0, 0, 0, 0.5);
  cursor: move;

  button {
    margin: 0 10px;
  }

  .close {
    margin: 0;
  }

  input {
    &:focus {
      outline: none;
    }

    margin-bottom: 20px;
  }
}

.text-area {
  resize: vertical;
  height: 150px;
  line-height: 150%;
  resize: vertical;
  flex-wrap: wrap;
}

.move-controls__left {
  background-image: url(../../src/assets/left.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.move-controls__right {
  background-image: url(../../src/assets/right.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.save-controls__title {
  font-weight: bold;
  font-size: 20px;
}

.close {
  position: absolute;
  top: 5px;
  right: 5px;
}

.save-controls__save-button:hover {
  background: $second-color;
  transition: all 0.1s ease-out;
  color: #fff;
}

.move-controls {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.download {
  background-image: url(../../src/assets/left.svg);
  transform: rotate(-90deg);
  background-repeat: no-repeat;
  background-position: center;
  width: 27px;
  height: 27px;
  background-color: #eee;
  border-radius: 5px;
}

.entry {
  position: relative;
  min-height: 42px;
  display: block;

  &:before {
    content: "";
    height: 100%;
    border-left: 1px solid #ccc;
    position: absolute;
    left: -20px;
  }

  &:first-child {
    &:after {
      height: 10px;
      border-radius: 10px 0 0 0;
    }

    &:before {
      width: 10px;
      height: 50%;
      top: 50%;
      margin-top: 1px;
      border-radius: 10px 0 0 0;
    }
  }

  &:after {
    content: "";
    width: 20px;
    transition: border 0.5s;
    border-top: 1px solid #ccc;
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: 1px;
  }

  &:last-child {
    &:before {
      width: 10px;
      height: 50%;
      border-radius: 0 0 0 10px;
    }

    &:after {
      height: 10px;
      border-top: none;
      transition: border 0.5s;
      border-bottom: 1px solid #ccc;
      border-radius: 0 0 0 10px;
      margin-top: -9px;
    }
  }

  &:only-child {
    &:after {
      width: 10px;
      height: 0px;
      margin-top: 1px;
      border-radius: 0px;
    }

    &:before {
      display: none;
    }
  }

  span {
    border: 1px solid #ccc;
    display: block;
    min-width: 150px;
    padding: 5px 10px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 14px;
    display: inline-block;
    border-radius: 5px;
    transition: all 0.5s;
  }
}

#tree .entry span:hover {
  background: #e6e6e6;
  color: #000;
  border-color: #a6a6a6;

  +.branch {
    .entry {
      span {
        background: #e6e6e6;
        color: #000;
        border-color: #a6a6a6;
      }

      &::after,
      &::before {
        border-color: #a6a6a6;
      }
    }

    &::before,
    .branch::before {
      border-color: #a6a6a6;
    }
  }
}

.goals-colors {
  display: flex;
  position: fixed;
  align-items: flex-end;
  margin-top: 20px;
  bottom: 0;
  width: 90%;
}

.goals-colors__level {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.goals-colors__level-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 30px;
  text-align: center;
  font-size: 16px;
  margin-bottom: 5px;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ccc;
}

.strategy-level {
  max-width: 37.5%;
}

.business-level {
  max-width: 25%;
}

.project-level {
  max-width: 37.5%;
}

.strategy-level-color {
  background-color: #fee0e0;
  width: 100%;
}

.business-level-color {
  background-color: #ffffcb;
  width: 100%;
}

.project-level-color {
  background-color: #c2f0ff;
  width: 100%;
}

.custom-link {
  stroke: #ccc !important;
}

.directory {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  min-width: 250px;
  width: 30%;
  height: 220px;
  border-radius: 5px;
  margin: 20px;
  border: 1px solid #bdcace;
}

.blue {
  background-color: #ffd78c;
}

.green {
  background-color: #cbbcf5;
}

.red {
  background-color: #6ae9f5;
}

.forth {
  background-color: #ffaa56;
}

.fifth {
  background-color: #f4ea7d;
}

.card__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.card__logo img {
  width: 50px;
  margin-top: 50%;
}

.card__amount {
  font-size: 32px;
  font-weight: bold;
}

.card__description {
  width: 80%;
  margin: 0 5px;
}

.card__text {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card__controls {
  color: #337ab7;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: $main-color;
    width: 100%;
    height: 55px;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &:hover {
      color: $second-color;
      transition: color 0.3s;
    }
  }
}

.chart-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.chart-notification__text {
  font-size: 24px;
  font-weight: bold;
}

.form-error {
  border: 1px solid #e74c3c;
}

/* input styles */

.label-regular {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.input-regular {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 28px;
  width: 215px;
  padding: 0 25px 0 10px;

  &:hover {
    border-color: $second-color;
  }

  &:focus {
    outline: 2px solid rgba(45, 50, 84, 0.5);
  }
}

.dropdown {
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 29px;
  width: 60px;
}

.divider {
  margin: 16px 0;
  color: #151429;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: #000;
  width: 250px;

  .divider-inner-text {
    &::before {
      display: flex;
      position: relative;
      top: 50%;
      width: 40%;
      border-top: 1px solid #fff;
      content: "";
    }

    font-weight: bold;

    &::after {
      display: flex;
      position: relative;
      top: -50%;
      left: 60%;
      width: 40%;
      border-top: 1px solid #fff;
      content: "";
    }
  }
}

.floating-message {
  text-align: center;
  position: absolute;
  left: 80%;
  top: 10%;
  width: 200px;
  height: 100px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.spinner-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.form-activity-container {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.form-activity__content {
  width: 64%;
}

.form-relationship-container {
  width: 380px;
}

.form-relationship__content {
  width: 64%;
}

.form-relationship {
  display: flex;
  background-color: #fff;
  position: absolute;
  min-height: 300px;
  max-height: 500px;
  width: 500px;
  z-index: 3;
  overflow-y: scroll;

  label input {
    margin-left: 5px;
  }
}

.form-activity {
  background-color: #fff;
  position: absolute;
  height: 200px;
  width: 380px;
  z-index: 3;
  overflow-y: scroll;

  label input {
    margin-left: 5px;
  }

  &__button-close {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 100%;
    text-align: left;
    padding: 2px 5px;
  }

  &__button-open {
    background-color: #fff;
    text-align: left;
    height: 40px;
    width: 380px;
    padding-left: 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  }
}

.form-maturity-container {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.form-maturity {
  background-color: #fff;
  position: absolute;
  min-height: 200px;
  width: 380px;
  z-index: 3;
  height: fit-content;
  overflow-y: scroll;

  label input {
    margin-left: 5px;
  }
}

.form-application-container {
  display: flex;
  flex-direction: column;
  min-width: 380px;
  height: 45px;
}

.form-application-relationship {
  background-color: #fff;
  position: absolute;
  min-height: 200px;
  z-index: 3;
  height: fit-content;
  overflow-y: scroll;

  label input {
    margin-left: 5px;
  }
}

.nav-icon {
  color: #fff;
  font-size: 26px;
}

.nav-icon-black {
  color: #000;
  font-size: 26px;
}

.tag-text {
  display: flex;
  position: relative;
  border-radius: 5px;
  background-color: #eee;
  padding: 8px 10px 8px 5px;

  button {
    position: absolute;
    top: -2px;
    right: 4%;
    font-size: 14px;
    color: #333;
  }
}

.selected-item {
  background-color: $second-color !important;
  color: #fff !important;

  &__button {
    color: #fff !important;
  }

  &:hover {
    background-color: $accent-color !important;
  }
}

#card-0 {
  margin-right: 50px;
  position: sticky;
  left: 0;
  z-index: 99;
  box-shadow: 10px 10px 5px 7px rgba(0, 0, 0, 0.13);
}

.backlog-shadow {
  box-shadow: 10px 10px 5px 7px rgba(0, 0, 0, 0.13);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  // transition: visibility 0s linear 0.1s, font-size 0.1s ease; /* Transition the visibility and font-size */

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1000;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.about-page {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    width: 100%;
    padding: 100px;
    border-radius: 12px;
    min-height: 500px;
    margin-top: 50px;

    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      gap: 20px;

      ul {
        list-style: inside;
      }
    }

    &__title {
      font-size: 3em;
      margin-bottom: 20px;
    }
  }
}

.item-list {
  display: flex;
  flex-direction: column;
}

.transition-item {
  display: flex;
}

.notices {
  display: flex;
}

.grid {
  transition: grid-template-columns 300ms;
}

.grid.mini {
  grid-template-columns: repeat(12, 1fr);
}

.grid.max {
  grid-template-columns: 2fr repeat(10, 1fr);
}

.grid-item {
  justify-self: end;
}

.nav-transition-enter {
  opacity: 0;
}

.nav-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.nav-transition-exit {
  opacity: 1;
}

.nav-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}