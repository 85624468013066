.skeleton {
    background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
    pointer-events: none;

}

.skeleton_gray {
    background-color: #f5f5f5 !important;
    pointer-events: none;

}
  
@keyframes shine {
	to {
		background-position: right -40px top 0;
	}
}