/* login styles */



.sign-up {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 90%;
	width: 100%;
	background-size: cover;
	background-position: center;
}

  // disclamer
.rectangle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	width: 2%;
	max-height: 30vh;
  min-height: 230px;
	background: #1a6ee4;
	transform: scale(0);
	border-radius: 4px;
	color: white;
	opacity: 0;
	overflow: hidden;
  margin: 30px 0;
	animation: scale-in .1s ease-out forwards,
		expand .35s .25s ease-out forwards;
}

.notification-text {
	display: flex;
	align-items: center;
	padding: 20px 20px;
	font-family: 'Roboto', sans-serif;
	font-size: 14px;
  width: 100%;
  height: 100%;
	animation: fade-in .65s ease-in forwards;
  
}

@keyframes scale-in {
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes expand {
	50% {
		width: 720px;
	}
	100% {
		width: 720px;
		border-radius: 4px;
		box-shadow: 0px 1px 3px 0px rgba(0,0,0,.2),
								0px 1px 1px 0px rgba(0,0,0,.14),
								0px 3px 3px -1px rgba(0,0,0,.12);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: .8;
	}
}
  
  .form {
	background-color: white;
	padding: 20px 60px;
	border-radius: 10px;
  
	a {
	  text-decoration: none;
	  color: $second-color;
	}
  }
  
  .form__title {
	color: #000000;
	text-align: center;
	font-size: 20px;
  }

  .action-buttons {
	display: flex;
	gap: 15px;
	width: 100%;
  }
  
  .form__button {
	width: 100%;
	height: 50px;
	padding: 10px;
	border: none;
	background-color: $second-color;
	color: white;
	border-radius: 5px;
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
	margin-top: 15px;
	margin-bottom: 30px;
	max-width: 300px;
	align-self: center;
  }
  
  .input-container {
	display: flex;
	flex-direction: column;
	width: 280px;
	position: relative;
  }

  .input-basic-container {
	display: flex;
	flex-direction: column;
	width: 280px;
	position: relative;
  }
  
  .input-container__eye {
	cursor: pointer;
	color: #999;
	position: absolute;
	top: 45px;
	left: 250px;
	font-size: 20px;
  
	&:hover {
	  color: #2f2f2f;
	}
  }

  .input-basic-container__eye {
	cursor: pointer;
	color: #999;
	position: absolute;
	top: 36px;
	left: 250px;
	font-size: 20px;
  
	&:hover {
	  color: #2f2f2f;
	}
  }
  
  .input-container__input {
	padding: 15px 35px 15px 15px;
	margin: 10px 0px;
	border-radius: 5px;
	border: 1px solid gray;
  
	&:invalid[focused="true"] {
	  border: 1px solid red;
  
	  ~ .input-container__input-error {
		display: block;
	  }
	}
  }

  .input-basic-container__input {
  
	&:invalid[focused="true"] {
	  border: 1px solid red;
  
	  ~ .input-basic-container__input-error {
		display: block;
	  }
	}
  }
  
  .input-container__label {
	font-size: 12px;
	color: gray;
  }

  .input-container__input-error {
	font-size: 12px;
	padding: 3px;
	color: red;
	display: none;
  }

  .input-basic-container__input-error {
	font-size: 12px;
	padding: 3px;
	color: red;
	display: none;
  }

  .action-buttons {
	display: flex;
	flex-direction: column;
	gap: 15px;
  }
  
  .signup-terms  {
	display: flex;
	align-items: center;
	
	input {
	  margin: 12px 5px 12px 0;
	}
  }
  
  .input-fields {
	display: flex;
	flex-wrap: wrap;
	max-width: 600px;
	gap: 1rem;
	justify-content: space-between;
  }
  
  .form-buttons-container {
	display: flex;
	justify-content: space-between;

	.form-button {
	  align-self: center;
	  background-color: #ffaf00;
	  border: none;
	  border-radius: 5px;
	  color: #fff;
	  cursor: pointer;
	  font-size: 18px;
	  font-weight: 700;
	  height: 50px;
	  margin-bottom: 30px;
	  margin-top: 15px;
	  max-width: 300px;
	  padding: 10px;
	  width: 100%;
	}
  }
  
  .form-buttons-container__login {
	margin-top: 10px;
  }
  
  .login-page {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	margin: 5px;
  
	.form {
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  flex-direction: column;
	  width: 30%;
	  height: 40%;
	  border: 1px solid #ccc;
	  border-radius: 5px;
	  background-color: #fff;
  
	  .form-title {
		margin-bottom: 20px;
		font-weight: 550;
		font-size: 1.5em;
	  }
  
	  .input-field {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-bottom: 20px;
		position: relative;
  
		.eye {
		  cursor: pointer;
		  color: #999;
		  position: absolute;
		  top: 29px;
		  left: 230px;
  
		  &:hover {
			color: #2f2f2f;
		  }
		}
	  }
	}
  }
  