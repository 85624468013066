.capabilities-report {
  display: flex;
  flex-direction: column;
  background-color: #eeeef2;
  margin: 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.5s ease;

  &__chart {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
    padding: 20px;
  }

  .heatmap-switch {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 15px;
    transition: all 0.2s;
  }
}

// .capabilities-report-info {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 3rem;
//   // width: 100%;
//   // margin-top: -35px;
//   margin-bottom: 20px;
// }

.first-level-capability {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 13px;
  gap: 5px;
  font-size: 12px;
  position: relative;
  width: 10%;
  min-width: 100px;
  height: auto;
  z-index: 1;
  background: #ffffff;
  border-radius: 12px;
  transition: all 0.2s;

  &:first-child {
    margin-left: 10px;
  }
}

.leading-title {
  word-wrap: break-word;
}

.task-title {
  word-wrap: break-word;
}

.second-level-capability {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 13px 13px;
  gap: 10px;
  position: relative;
  width: 80%;
  min-height: 50px;
  border-radius: 8px;
  transition: all 0.2s;
}

.second-level-capability h3 {
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #1e1e1e;
  transition: all 0.2s;
}

.third-level-capability {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  width: 90%;
  min-height: 50px;
  background: #ffffff;
  border-radius: 8px;
  transition: all 0.2s;
  color: #1e1e1e;
}

.third-level-capability p {
  padding: 4px;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #1e1e1e;
}

.maturity-min {
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 6px;
  left: 5px;
  top: 5px;
  transition: all 0.2s;
}

.maturity-color-1 {
  background-color: #c1c1c1;
}

.maturity-color-2 {
  background-color: #ff3d67;
}

.maturity-color-3 {
  background-color: #ff9a59;
}

.maturity-color-4 {
  background-color: #fdef59;
}

.maturity-color-5 {
  background-color: #83cca3;
}

.maturity-color-6 {
  background-color: #37a19a;
}

.no-maturity-color {
  background-color: #c9d5f2;
}
