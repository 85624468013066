.form-container {
	height: 75vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.upload-files-container {
	padding: 30px 60px;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px;
}

.drag-file-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
	margin: 10px 0 15px;
	padding: 30px 50px;
	text-align: center;
}

.drag-file-area .upload-icon {
  margin-top: 20px;
	font-size: 50px;
}

.drag-file-area h3 {
	font-size: 26px;
	margin: 15px 0;
}

.download-select-title h3 {
	font-size: 18px;
	margin: 15px 0;
}

#form-file-upload {
  // height: 16rem;
  // width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #fff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  color: $second-color;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

#ac-chart-container {
  width: 90vw;
}