.lds-facebook {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  
	div {
		display: inline-block;
		position: absolute;
		left: 8px;
		width: 16px;
		background: $second-color;
		animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	
		&:nth-child(1) {
			left: 8px;
			animation-delay: -0.24s;
		}
	
		&:nth-child(2) {
			left: 32px;
			animation-delay: -0.12s;
		}
	
		&:nth-child(3) {
			left: 56px;
			animation-delay: 0;
		}
	}
}
  
@keyframes lds-facebook {
	0% {
	top: 8px;
	height: 64px;
	}

	50%, 100% {
	top: 24px;
	height: 32px;
	}
}
  
  .lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 64px;
		height: 64px;
		margin: 8px;
		border: 8px solid $second-color;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: $second-color transparent transparent transparent;
	
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
	
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
	
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}
  
@keyframes lds-ring {
	0% {
	  	transform: rotate(0deg);
	}
  
	100% {
	 	transform: rotate(360deg);
	}
}