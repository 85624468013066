.timeline-container {
	display: flex;
	justify-content: center;
	align-items: start;
	flex-direction: column;
	position: fixed;
	width: 88.7%;
	overflow: hidden;
	bottom: 0%;
	// right: 10%;
	z-index: 5;
	background-color: #cacaca94;
	padding: 30px 5px 30px 5px;
	border-radius: 10px;
}

.timeline-content {
	display: flex;
	width: 100%;
	height: 100%;
	// align-items: center;
	// justify-content: center;
	flex-direction: column;
}

.timeline-switch {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 1px;
	right: 0;
	margin: 3px;
	transition: all .2s;
	margin-bottom: 50px;
}

.start-end-date {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 0px;
}

#r1 {
	position: absolute;
	width: 100%;
	cursor: pointer;
	// background-color: transparent;
	top: 10%;
	z-index: 1;

	appearance: none;
	height: 0.3rem;
	outline: none;
	border-radius: 0.3rem;

	&::-webkit-slider-thumb {
		appearance: none;
		width: 1.5rem;
		height: 1.5rem;
		background-color: #F44336;
		border: 0.3rem solid #fff;
		border-radius: 1rem;
		cursor: pointer;
	
	}

}

// input[type=range] {
// 	height: 33px;
// 	-webkit-appearance: none;
// 	margin: 10px 0;
// 	width: 100%;
// }

// input[type=range]:focus {
// 	outline: none;
// }

// input[type=range]::-webkit-slider-runnable-track {
// 	width: 100%;
// 	height: 12px;
// 	cursor: pointer;
// 	animate: 0.2s;
// 	box-shadow: 1px 1px 1px $second-color-light;
// 	background: $second-color-light;
// 	border-radius: 1px;
// 	border: 1px solid $second-color-light;
// }

// input[type=range]::-webkit-slider-thumb {
// 	// box-shadow: 2px 2px 3px #42464F;
// 	border: 2px solid $second-color;
// 	height: 13px;
// 	width: 13px;
// 	border-radius: 13px;
// 	background: red;
// 	cursor: pointer;
// 	-webkit-appearance: none;
// 	margin-top: -1px;
// 	z-index: 99;

// }

// input[type=range]:focus::-webkit-slider-runnable-track {
// 	background: $second-color-light;
// }

// input[type=range]::-moz-range-track {
// 	width: 100%;
// 	height: 12px;
// 	cursor: pointer;
// 	animate: 0.2s;
// 	box-shadow: 1px 1px 1px $second-color-light;
// 	background: $second-color-light;
// 	border-radius: 1px;
// 	border: 1px solid $second-color-light;
// }

// input[type=range]::-moz-range-thumb {
// 	box-shadow: 2px 2px 3px #42464F;
// 	border: 2px solid #A9C3E5;
// 	height: 23px;
// 	width: 23px;
// 	border-radius: 23px;
// 	background: $second-color-light;
// 	cursor: pointer;
// 	z-index: 99;
// }

// input[type=range]::-ms-track {
// 	width: 100%;
// 	height: 12px;
// 	cursor: pointer;
// 	animate: 0.2s;
// 	background: transparent;
// 	border-color: transparent;
// 	color: transparent;
// }

// input[type=range]::-ms-fill-lower {
// 	background: $second-color-light;
// 	border: 1px solid $second-color-light;
// 	border-radius: 2px;
// 	box-shadow: 1px 1px 1px $second-color-light;
// }

// input[type=range]::-ms-fill-upper {
// 	background: $second-color-light;
// 	border: 1px solid $second-color-light;
// 	border-radius: 2px;
// 	box-shadow: 1px 1px 1px $second-color-light;
// }

// input[type=range]::-ms-thumb {
// 	margin-top: 1px;
// 	box-shadow: 2px 2px 3px #42464F;
// 	border: 2px solid $second-color-light;
// 	height: 23px;
// 	width: 23px;
// 	border-radius: 23px;
// 	background: $second-color-light;
// 	cursor: pointer;
// 	z-index: 99;
// }

// input[type=range]:focus::-ms-fill-lower {
// 	background: $second-color-light;
// }

// input[type=range]:focus::-ms-fill-upper {
// 	background: $second-color-light;
// }
