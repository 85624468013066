.anychart-credits {
	display: none;
}


.pert-container {
	display: flex;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	padding: 20px;
	border-radius: 20px;
	margin-top: 30px;

	/* height: 80vh; */
	width: 100%;
}

#anychart-menu-wrapper {
	display: none;
}

.pert-item {
	width: 300px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.pert-item__title {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-color: #d7ddfd;
	color: #474747;
	display: flex;
	width: 100%;
	height: 50%;
	font-weight: bold;
	font-size: 12px;
}

.pert-item__data {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	height: 50%;
	border: 1px solid #eee;
	width: 100%;
}

.pert-item__start-date, .pert-item__end-date {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 12px;
	width: 30%;
	height: 100%;
	padding: 5px;
}

.pert-item__effort {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 12px;
	width: 30%;
	height: 100%;
	padding: 5px;
	border-right: 1px solid #eee;
	border-left: 1px solid #eee;
}

.pert-chart {
	width: 80%;
	height: 80%;
	display: flex;
	justify-content: space-evenly;

	/* align-items: center; */
	flex-direction: column;
	gap: 50px;
}