@keyframes fade-in {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes move-in {
    from {
      opacity: 0;
      top: -100%;
    }
  
    to {
      top: 0%;
      opacity: 1;
    }
  }